import { Button, Divider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import EquipmentDashboard from "./equipmentDashboard/equipmentDashboard";
import GrowthForecastRetention from "./growthForecastRetention/growthForecastRetention";
import EquipmentTransaction from "./equipmentTransaction/equipmentTransaction";
import StoreSeasonality from "./storeSeasonality/storeSeasonality";
import BPD from "./bpd/bpd";
import DashboardHeader from "../../reusableComponents/dashboardHeader/dashboardHeader";
import { getEquipmentAnalytic } from "../../../store/thunks/portalManagementThunk";

const Equipments = () => {
  const { selectedClient, selectedAccountType } = useAppSelector(
    (state: any) => state.portalManagement
  );
  const { userInfo } = useAppSelector((state: any) => state.authentication);

  const { equipmentAnalyticRawData } = useAppSelector(
    (state: any) => state.equipmentManagement
  );

  const dispatch = useAppDispatch();

  const [showEquipmentDashboard, setShowEquipmentDashboard] = useState(true);
  const [showGrowthForecastRetention, setShowGrowthForecastRetention] =
    useState(false);
  const [showEquipmentTransaction, setShowEquipmentTransaction] =
    useState(false);
  const [showStoreSeasonality, setShowStoreSeasonality] = useState(false);
  const [showBPD, setShowBPD] = useState(false);

  const handleEquipmentNavigation = (section: string) => {
    if (section === "equipmentDashboard") {
      setShowEquipmentDashboard(true);
      setShowGrowthForecastRetention(false);
      setShowEquipmentTransaction(false);
      setShowStoreSeasonality(false);
      setShowBPD(false);
    } else if (section === "growthForecastRetention") {
      setShowEquipmentDashboard(false);
      setShowGrowthForecastRetention(true);
      setShowEquipmentTransaction(false);
      setShowStoreSeasonality(false);
      setShowBPD(false);
    } else if (section === "equipmentTransaction") {
      setShowEquipmentDashboard(false);
      setShowGrowthForecastRetention(false);
      setShowEquipmentTransaction(true);
      setShowStoreSeasonality(false);
      setShowBPD(false);
    } else if (section === "storeSeasonality") {
      setShowEquipmentDashboard(false);
      setShowGrowthForecastRetention(false);
      setShowEquipmentTransaction(false);
      setShowStoreSeasonality(true);
      setShowBPD(false);
    } else if (section === "bpd") {
      setShowEquipmentDashboard(false);
      setShowGrowthForecastRetention(false);
      setShowEquipmentTransaction(false);
      setShowStoreSeasonality(false);
      setShowBPD(true);
    }
  };

  const [equipmentRegion, setequipmentRegion] = useState(false);

  useEffect(() => {
    console.log("userInfo", userInfo);

    if (selectedClient && selectedAccountType) {
      console.log("selectedClient", selectedClient);
      console.log("selectedClient", selectedAccountType);
      dispatch(
        getEquipmentAnalytic({
          customer_id: selectedClient.customer_id,
          portal_account: selectedAccountType.client_type_index,
          user: userInfo.role,
        })
      ).then((result: any) => {
        console.log("result?.payload", result);

        // state.equipmentRegion = action.payload.equip_region;
        // state.equipmentSummary = action.payload.equip_summary_dic;
        // state.equipmentTransaction = action.payload.equip_transaction_dic;
        // state.equipmentMachines = action.payload.machine;
        // state.retailChain = action.payload.retail_chain;
        // state.equipmentAnalyticRawData = action.payload;
      });
    }
  }, []);

  const equipmentDashboardFilterData = () => {
    console.log("equipmentAnalyticRawData", equipmentAnalyticRawData);
  };

  useEffect(() => {
    if (equipmentAnalyticRawData) equipmentDashboardFilterData();
  }, [equipmentAnalyticRawData]);

  return (
    <Grid container sx={{}}>
      <Grid
        container
        sx={{
          backgroundColor: "#4C4C4C",
          borderTop: "5px solid white",
          height: "40px",
          paddingLeft: "10px",
        }}
      >
        <Button
          onClick={() => handleEquipmentNavigation("equipmentDashboard")}
          sx={{
            color: showEquipmentDashboard ? "#38B54A" : "white",
            padding: "0px 20px 0px 20px",
          }}
        >
          Dashbaord
        </Button>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ color: "white", backgroundColor: "white" }}
        />
        <Button
          onClick={() => handleEquipmentNavigation("growthForecastRetention")}
          sx={{
            color: showGrowthForecastRetention ? "#38B54A" : "white",
            padding: "0px 20px 0px 20px",
          }}
        >
          Growth/Forecast & Retention
        </Button>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ color: "white", backgroundColor: "white" }}
        />
        <Button
          onClick={() => handleEquipmentNavigation("equipmentTransaction")}
          sx={{
            color: showEquipmentTransaction ? "#38B54A" : "white",
            padding: "0px 20px 0px 20px",
          }}
        >
          Transactions
        </Button>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ color: "white", backgroundColor: "white" }}
        />
        <Button
          onClick={() => handleEquipmentNavigation("storeSeasonality")}
          sx={{
            color: showStoreSeasonality ? "#38B54A" : "white",
            padding: "2px 20px 2px 20px",
          }}
        >
          Store & Seasonality
        </Button>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ color: "white", backgroundColor: "white" }}
        />
        <Button
          onClick={() => handleEquipmentNavigation("bpd")}
          sx={{
            color: showBPD ? "#38B54A" : "white",
            padding: "2px 20px 2px 20px",
          }}
        >
          BPD
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          borderRight: "10px solid white",
          borderLeft: "10px solid white",
          backgroundColor: "rgba(176, 215, 255, 0.1)",
          height: "100vh",
          border: "1ps solid red",
        }}
      >
        <DashboardHeader
          title={selectedClient.customer_name}
          headerType={"equipment"}
        />
        {showEquipmentDashboard && <EquipmentDashboard />}
        {showGrowthForecastRetention && <GrowthForecastRetention />}
        {showEquipmentTransaction && <EquipmentTransaction />}
        {showStoreSeasonality && <StoreSeasonality />}
        {showBPD && <BPD />}
      </Grid>
    </Grid>
  );
};
export default Equipments;
