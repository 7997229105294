import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import Spinner from "../../components/reusableComponents/spinner/spinner";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { loginInfo } from "../../types/formTypes";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { loginUser } from "../../store/thunks/authenticationThunk";
import { reset } from "../../store/features/authentication/autehnticationSlice";
let loginInitialValues: loginInfo = {
  username: "",
  password: "",
};
const Login = () => {
  const { loading, error, loggedIn, previousLocation } = useAppSelector(
    (state: any) => state.authentication
  );

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");

  const validateLogin = Yup.object({
    username: Yup.string().email("Invalid email").required("Email is Required"),
    password: Yup.string()
      .min(8, "Too Short!")
      .max(50, "Too Long!")
      .required("Password is Required"),
  });
  const loginForm = useFormik({
    initialValues: loginInitialValues,
    validationSchema: validateLogin,
    onSubmit: (values: loginInfo) => {
      setLoginError(false);
      setLoginErrorMessage("");

      dispatch(loginUser(values))
        .then((result) => {
          if (result?.payload?.result) {
            loginForm.resetForm();
            navigate("/dashboard");
          } else {
            setLoginError(true);
            setLoginErrorMessage(result?.payload?.message);
          }
        })
        .catch(() => {});
    },
  });
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    dispatch(reset());
    if (loggedIn) {
      navigate("/dashboard");
    }
    if (previousLocation && previousLocation !== "") {
      if (previousLocation?.includes("resetpasswordverification")) {
        setOpenSnackbar(true);
      }
    }
  }, [navigate, loggedIn, previousLocation]);

  return (
    <Grid sx={{ minHeight: "600px" }}>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <Spinner loading={loading} />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={5000}
          open={openSnackbar}
          message={"Password Has been reset successfully"}
          key={"topcenter"}
          onClose={() => setOpenSnackbar(false)}
        />
        <Grid
          container
          item
          xs={6}
          sx={{
            display: "flex",
            // zIndex: 2,
            justifyContent: "center",
          }}
        >
          <Paper
            sx={{
              // zIndex: 2,
              width: "100%",
              maxWidth: "600px",
              p: 3,
            }}
            elevation={3}
          >
            <form onSubmit={loginForm.handleSubmit}>
              <Grid container sx={{ gap: 1.5 }}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100px",
                    backgroundColor: "#00b93f",
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{ fontWeight: "bold", color: "#ccc" }}
                  >
                    Log In
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{}}>
                  <Typography>Email</Typography>
                  <TextField
                    sx={{}}
                    placeholder="Email"
                    name="username"
                    inputProps={{}}
                    fullWidth
                    value={loginForm.values.username}
                    error={
                      loginForm.touched.username &&
                      Boolean(loginForm.errors.username)
                    }
                    helperText={loginForm.errors.username}
                    onChange={loginForm.handleChange}
                  />
                </Grid>
                <Grid item xs={12} sx={{}}>
                  <Typography>Password</Typography>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Password"
                    name="password"
                    fullWidth
                    value={loginForm.values.password}
                    error={
                      loginForm.touched.password &&
                      Boolean(loginForm.errors.password)
                    }
                    onChange={loginForm.handleChange}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Grid item sx={{}}>
                    Remember me
                  </Grid>
                  <Grid item sx={{}}>
                    <Button onClick={() => navigate("/resetpassword")}>
                      Forgot password?
                    </Button>
                  </Grid>
                </Grid>
                {error && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "red",
                    }}
                  >
                    <Typography>{error?.data?.message}</Typography>
                  </Grid>
                )}
                {loginError && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "red",
                    }}
                  >
                    <Typography>{loginErrorMessage}</Typography>
                  </Grid>
                )}
                <Grid item xs={12} sx={{}}>
                  <Button
                    variant="contained"
                    sx={{ width: "100%", backgroundColor: "#56baed" }}
                    onClick={() => loginForm.submitForm}
                    type="submit"
                  >
                    Log In
                  </Button>
                </Grid>
                {/* <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Don't Have an account?{" "}
                  <Button onClick={() => navigate("/register")}>Sign Up</Button>
                </Grid> */}
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
