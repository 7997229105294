import {
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  Snackbar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { resetpasswordInfo } from "../../types/formTypes";
import { useFormik } from "formik";
import Spinner from "../../components/reusableComponents/spinner/spinner";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { resetPasswordLink } from "../../store/thunks/authenticationThunk";
import { useNavigate } from "react-router-dom";
import { reset } from "../../store/features/authentication/autehnticationSlice";

let loginInitialValues: resetpasswordInfo = {
  username: "",
};
const ResetPassword = () => {
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(reset());
    if (loggedIn) navigate("/dashboard");
  }, []);
  const { loading, error, response, loggedIn } = useAppSelector(
    (state: any) => state.authentication
  );
  const [resetError, setResetError] = useState(false);

  const dispatch = useAppDispatch();

  const validateResetpassword = Yup.object({
    username: Yup.string().email("Invalid email").required("Email is Required"),
  });
  const resetPasswordForm = useFormik({
    initialValues: loginInitialValues,
    validationSchema: validateResetpassword,
    onSubmit: (values) => {
      dispatch(resetPasswordLink(values)).then((result) => {
        if (result?.payload?.result) {
          setResetError(true);
          resetPasswordForm.resetForm();
        } else {
          setResetError(true);
        }
      });
    },
  });
  return (
    <Grid sx={{ minHeight: "600px" }}>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <Spinner loading={loading} />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={5000}
          open={resetError}
          message={response?.message}
          key={"topcenter"}
          onClose={() => setResetError(false)}
        />
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            zIndex: 2,
            justifyContent: "center",
          }}
        >
          <Paper
            sx={{
              zIndex: 2,
              width: "100%",
              maxWidth: "600px",
              p: 3,
            }}
            elevation={3}
          >
            <form onSubmit={resetPasswordForm.handleSubmit}>
              <Grid container sx={{ gap: 1.5 }}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100px",
                    backgroundColor: "#00b93f",
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{ fontWeight: "bold", color: "#ccc" }}
                  >
                    Reset Password
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{}}>
                  <Typography>Email</Typography>
                  <TextField
                    sx={{}}
                    placeholder="Email"
                    name="username"
                    inputProps={{}}
                    fullWidth
                    value={resetPasswordForm.values.username}
                    error={
                      resetPasswordForm.touched.username &&
                      Boolean(resetPasswordForm.errors.username)
                    }
                    helperText={resetPasswordForm.errors.username}
                    onChange={resetPasswordForm.handleChange}
                  />
                </Grid>
                {(error || resetError) && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "red",
                    }}
                  >
                    <Typography>{error?.data?.message}</Typography>
                  </Grid>
                )}
                <Grid item xs={12} sx={{}}>
                  <Button
                    variant="contained"
                    sx={{ width: "100%", backgroundColor: "#56baed" }}
                    onClick={() => resetPasswordForm.submitForm}
                    type="submit"
                  >
                    Send Reset Passowrd Link
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
