import { Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import chart from "./../../../../assets/chart.png";
import graph from "./../../../../assets/graph.png";
import purchase from "./../../../../assets/purchase.png";
import twohands from "./../../../../assets/twohands.png";
import npsscore from "./../../../../assets/npsscore.png";
import error from "./../../../../assets/error.png";
import PercentIcon from "@mui/icons-material/Percent";
import { useAppSelector } from "../../../../hooks/hooks";

import { GetArray, GetObject, WithCommas } from "../../../../utils/utils";

import MiniSpinner from "../../../reusableComponents/miniSpinner/miniSpinner";
const AnalyticDashboard = () => {
  const {
    selectedClient,
    forecastData,
    revinvData,
    selectedBranch,
    selectedDepartment,
    purchaseTagRawData,
    purchaseFreqRawData,
    wsr12RawData,
    atRiskData,
    leadRawData,
    lostRawData,
    gainRawData,
    benchRawData,
    sseRawData,
    sseSumRawData,
    activeAccountLoading,
    purchaseDistributionLoading,
    retentionRateLoading,
    npsScoreLoading,
    atRiskLoading,
    retentionLoading,
    emailReportsLoading,
    marketCustRawData,
  } = useAppSelector((state: any) => state.portalManagement);

  const [dashboardActiveAccount, setDashboardActiveAccount] = useState("");
  const [dashboardGrowthAccount, setDashboardGrowthAccount] = useState("");
  const [dashboardForecastActiveAccount, setDashboardForecastActiveAccount] =
    useState("");
  const [dashboardForecastGrowthAccount, setDashboardForecastGrowthAccount] =
    useState("");
  const [
    dashboardTransactionInvoiceActive,
    setDashboardTransactionInvoiceActive,
  ] = useState("");
  const [
    dashboardTransactionInvoiceGrowth,
    setDashboardTransactionInvoiceGrowth,
  ] = useState("");
  const [dashboardForecastInvoiceActive, setDashboardForecastInvoiceActive] =
    useState("");
  const [dashboardForecastInvoiceGrowth, setDashboardForecastInvoiceGrowth] =
    useState("");

  const [dashboardRevenueActiveAccount, setDashboardRevenueActiveAccount] =
    useState("");
  const [dashboardRevenueAccountGrowth, setDashboardRevenueAccountGrowth] =
    useState("");
  const [dashboardForecastRevenue, setDashboardForecastRevenue] = useState("");
  const [dashboardForecastRevenueGrowth, setDashboardForecastRevenueGrowth] =
    useState("");

  const [dashboardCustomerPurchase, setDashboardCustomerPurchase] =
    useState("");
  const [dashboardCustomerFrquency, setDashboardCustomerFrquency] =
    useState("");
  const [dashboardRetentionRate, setDashboardRetentionRate] = useState("");
  const [dashboardNPS, setDashboardNPS] = useState("");
  const [dashboardAtRiskStableCustomer, setDashboardAtRiskStableCustomer] =
    useState("");
  const [dashboardAtRiskLCustomer, setDashboardAtRiskLCustomer] = useState("");
  const [dashboardAtRiskUCustomer, setDashboardAtRiskUCustomer] = useState("");
  const [dashboardCustomerLead, setDashboardCustomerLead] = useState("");
  const [dashboardCustomerGain, setDashboardCustomerGain] = useState("");
  const [dashboardCustomerLost, setDashboardCustomerLost] = useState("");

  const [dashboardCEM, setDashboardCEM] = useState("");
  const [dashboardSSM, setDashboardSSM] = useState("");
  const [dashboardSSCR, setDashboardSSCR] = useState("");
  const [marketCustData, setMarketCustData] = useState<any>();

  const dashboardFilterData = () => {
    if (revinvData) {
      const revInvDataFilter = GetObject(
        GetArray(revinvData).filter((item: any) => {
          return (
            item.BRANCH === selectedBranch.value &&
            item.PRODUCT_TYPE_ALL === selectedDepartment.value
          );
        })
      );
      if (Object.keys(revInvDataFilter).length !== 0) {
        if (revInvDataFilter.ACCOUNT_GROWTH) {
          const lastIndex = revInvDataFilter.ACCOUNT_GROWTH.length
            ? revInvDataFilter.ACCOUNT_GROWTH.length - 1
            : 0;
          if (revInvDataFilter.TOTAL_ACCOUNT)
            setDashboardActiveAccount(
              revInvDataFilter.TOTAL_ACCOUNT[lastIndex]
                ? revInvDataFilter.TOTAL_ACCOUNT[lastIndex]
                : ""
            );
          if (revInvDataFilter.ACCOUNT_GROWTH)
            setDashboardGrowthAccount(
              revInvDataFilter.ACCOUNT_GROWTH[lastIndex]
                ? revInvDataFilter.ACCOUNT_GROWTH[lastIndex]
                : ""
            );
          if (revInvDataFilter.INVOICE_COUNT)
            setDashboardTransactionInvoiceActive(
              WithCommas(
                revInvDataFilter.INVOICE_COUNT[lastIndex]
                  ? revInvDataFilter.INVOICE_COUNT[lastIndex]
                  : ""
              )
            );
          if (revInvDataFilter.INVOICE_GROWTH)
            setDashboardTransactionInvoiceGrowth(
              revInvDataFilter.INVOICE_GROWTH[lastIndex]
                ? revInvDataFilter.INVOICE_GROWTH[lastIndex]
                : ""
            );
          if (revInvDataFilter.TOTAL_REVENUE)
            setDashboardRevenueActiveAccount(
              WithCommas(
                revInvDataFilter.TOTAL_REVENUE[lastIndex]
                  ? revInvDataFilter.TOTAL_REVENUE[lastIndex]
                  : ""
              )
            );
          if (revInvDataFilter.REVENUE_GROWTH)
            setDashboardRevenueAccountGrowth(
              revInvDataFilter.REVENUE_GROWTH[lastIndex]
                ? revInvDataFilter.REVENUE_GROWTH[lastIndex]
                : ""
            );
          if (revInvDataFilter.retention_rate)
            setDashboardRetentionRate(
              revInvDataFilter.retention_rate[lastIndex]
                ? Number(revInvDataFilter.retention_rate[lastIndex]).toFixed(1)
                : ""
            );
        }
      } else {
        setDashboardActiveAccount("-");
        setDashboardGrowthAccount("-");
        setDashboardTransactionInvoiceActive("-");
        setDashboardTransactionInvoiceGrowth("-");
        setDashboardRevenueActiveAccount("-");
        setDashboardRevenueAccountGrowth("-");
      }
    }
    if (forecastData) {
      const forecastDataFilter = GetObject(
        GetArray(forecastData).filter((item: any) => {
          return (
            item.BRANCH === selectedBranch.value &&
            item.PRODUCT_TYPE_ALL === selectedDepartment.value
          );
        })
      );
      if (Object.keys(forecastDataFilter).length !== 0) {
        if (forecastDataFilter.forecast_12_freq) {
          const freq_12_last_idx = forecastDataFilter.forecast_12_freq.length
            ? forecastDataFilter.forecast_12_freq.length - 1
            : 0;
          if (forecastDataFilter.forecast_12_freq)
            setDashboardForecastInvoiceActive(
              WithCommas(
                forecastDataFilter.forecast_12_freq[freq_12_last_idx]
                  ? forecastDataFilter.forecast_12_freq[freq_12_last_idx]
                  : ""
              )
            );
        }
        if (forecastDataFilter.freq_growth) {
          const freq_grth_last_idx = forecastDataFilter.freq_growth.length
            ? forecastDataFilter.freq_growth.length - 1
            : 0;
          if (forecastDataFilter.freq_growth)
            setDashboardForecastInvoiceGrowth(
              forecastDataFilter.freq_growth[freq_grth_last_idx]
                ? forecastDataFilter.freq_growth[freq_grth_last_idx]
                : ""
            );
        }
        if (forecastDataFilter.forecast_12_acc)
          setDashboardForecastActiveAccount(
            WithCommas(
              forecastDataFilter.forecast_12_acc[23]
                ? forecastDataFilter.forecast_12_acc[23]
                : ""
            )
          );
        if (forecastDataFilter.acc_growth)
          setDashboardForecastGrowthAccount(
            forecastDataFilter.acc_growth[23]
              ? forecastDataFilter.acc_growth[23]
              : ""
          );

        if (forecastDataFilter.forecast_12_rev)
          setDashboardForecastRevenue(
            WithCommas(
              forecastDataFilter.forecast_12_rev[23]
                ? forecastDataFilter.forecast_12_rev[23]
                : ""
            )
          );
        if (forecastDataFilter.rev_growth)
          setDashboardForecastRevenueGrowth(
            forecastDataFilter.rev_growth[23]
              ? forecastDataFilter.rev_growth[23]
              : ""
          );
      } else {
        setDashboardForecastActiveAccount("-");
        setDashboardForecastGrowthAccount("-");
        setDashboardForecastInvoiceActive("-");
        setDashboardForecastInvoiceGrowth("-");
        setDashboardForecastRevenue("-");
        setDashboardForecastRevenueGrowth("-");
      }
    }
    if (purchaseTagRawData) {
      const purchasetagRawDataFilter = GetObject(
        GetArray(purchaseTagRawData).filter(
          (item: any) =>
            item.BRANCH === selectedBranch.value &&
            item.PRODUCT_TYPE_ALL === selectedDepartment.value
        )
      );
      if (Object.keys(purchasetagRawDataFilter).length !== 0) {
        if (purchasetagRawDataFilter.ACCOUNT_PERCENT)
          setDashboardCustomerPurchase(
            purchasetagRawDataFilter.ACCOUNT_PERCENT[0]
              ? Number(purchasetagRawDataFilter.ACCOUNT_PERCENT[0]).toFixed(1)
              : ""
          );
      } else {
        setDashboardCustomerPurchase("-");
      }
    }
    if (purchaseFreqRawData) {
      const purchasefreqRawDataFilter = GetObject(
        GetArray(purchaseFreqRawData).filter(
          (item: any) =>
            item.BRANCH === selectedBranch.value &&
            item.PRODUCT_TYPE_ALL === selectedDepartment.value
        )
      );
      if (Object.keys(purchasefreqRawDataFilter).length !== 0) {
        if (purchasefreqRawDataFilter.cust_freq)
          setDashboardCustomerFrquency(
            purchasefreqRawDataFilter.cust_freq[0]
              ? Number(purchasefreqRawDataFilter.cust_freq[0]).toFixed(1)
              : ""
          );
      } else {
        setDashboardCustomerFrquency("-");
      }
    }
    if (wsr12RawData) {
      const nps_last_idx =
        wsr12RawData?.wsr && wsr12RawData?.wsr.length > 0
          ? wsr12RawData?.wsr.length - 1
          : 0;
      if (wsr12RawData.wsr)
        setDashboardNPS(
          wsr12RawData.wsr[nps_last_idx] ? wsr12RawData?.wsr[nps_last_idx] : ""
        );
    } else {
      setDashboardNPS("-");
    }
    if (atRiskData) {
      const atRiskDataFilter = GetObject(
        GetArray(atRiskData).filter(
          (item: any) =>
            item.BRANCH === selectedBranch.value &&
            item.PRODUCT_TYPE_ALL === selectedDepartment.value
        )
      );
      if (Object.keys(atRiskDataFilter).length !== 0) {
        if (atRiskDataFilter.CUST_FREQ) {
          setDashboardAtRiskStableCustomer(
            atRiskDataFilter.CUST_FREQ[2]
              ? Number(atRiskDataFilter.CUST_FREQ[2]).toFixed(1)
              : ""
          );
          setDashboardAtRiskLCustomer(
            atRiskDataFilter.CUST_FREQ[0]
              ? Number(atRiskDataFilter.CUST_FREQ[0]).toFixed(1)
              : ""
          );
          setDashboardAtRiskUCustomer(
            atRiskDataFilter.CUST_FREQ[1]
              ? Number(atRiskDataFilter.CUST_FREQ[1]).toFixed(1)
              : ""
          );
        }
      } else {
        setDashboardAtRiskStableCustomer("-");
        setDashboardAtRiskLCustomer("-");
        setDashboardAtRiskUCustomer("-");
      }
    }
    if (leadRawData) {
      const lprCustLeadsFilter = GetObject(
        GetArray(leadRawData).filter(
          (item: any) =>
            item.BRANCH === selectedBranch.value &&
            item.PRODUCT_TYPE_ALL === selectedDepartment.value
        )
      );
      if (Object.keys(lprCustLeadsFilter).length !== 0) {
        setDashboardCustomerLead(
          lprCustLeadsFilter.cust_lead[0] ? lprCustLeadsFilter.cust_lead[0] : ""
        );
      } else {
        setDashboardCustomerLead("-");
      }
    }
    if (gainRawData) {
      const lprCustGainFilter = GetObject(
        GetArray(gainRawData).filter(
          (item: any) =>
            item.BRANCH === selectedBranch.value &&
            item.PRODUCT_TYPE_ALL === selectedDepartment.value
        )
      );
      if (Object.keys(lprCustGainFilter).length !== 0) {
        setDashboardCustomerGain(
          lprCustGainFilter.cust_lead[0] ? lprCustGainFilter.cust_lead[0] : ""
        );
      } else {
        setDashboardCustomerGain("-");
      }
    }
    if (lostRawData) {
      const lprCustLostFilter = GetObject(
        GetArray(lostRawData).filter(
          (item: any) =>
            item.BRANCH === selectedBranch.value &&
            item.PRODUCT_TYPE_ALL === selectedDepartment.value
        )
      );
      if (Object.keys(lprCustLostFilter).length !== 0) {
        setDashboardCustomerLost(
          lprCustLostFilter.cust_lead[0] ? lprCustLostFilter.cust_lead[0] : ""
        );
      } else {
        setDashboardCustomerLost("-");
      }
    }
    if (benchRawData) {
      const benchRawDataFilter = GetObject(
        GetArray(benchRawData).filter(
          (item: any) =>
            item.BRANCH === selectedBranch.value &&
            item.dealer_type === "all" &&
            item.key_metric === "Email %"
        )
      );

      if (benchRawDataFilter.client_perf) {
        setDashboardCEM(
          benchRawDataFilter.client_perf
            ? Number(benchRawDataFilter.client_perf).toFixed(1)
            : ""
        );
      } else {
        setDashboardCEM("-");
      }
    }
    if (sseRawData) {
      const ssFilter = GetObject(
        GetArray(sseRawData).filter(
          (item: any) => item.EMAIL_TYPE === "General-Active"
        )
      );

      if (Object.keys(ssFilter).length !== 0) {
        setDashboardSSM(
          ssFilter.CLICK_RATE
            ? Number(
                ssFilter.CLICK_RATE[ssFilter.CLICK_RATE.length - 1]
              ).toFixed(1)
            : ""
        );
      } else {
        setDashboardSSM("-");
      }
    }
    if (sseSumRawData) {
      setDashboardSSCR(
        sseSumRawData.ss_m ? Number(sseSumRawData.ss_m).toFixed(1) : ""
      );
    } else {
      setDashboardSSCR("-");
    }
    if (marketCustRawData) {
      const tempMarketCustData = marketCustRawData[selectedBranch.value][
        selectedDepartment.value
      ]["12 months"]
        ? marketCustRawData[selectedBranch.value][selectedDepartment.value][
            "12 months"
          ]
        : [];

      tempMarketCustData.forEach((element: any) => {
        if (element.Programs === "With Email") {
          setMarketCustData(element["Change From Not Program"]);
        }
      });
    }
  };

  useEffect(() => {
    if (revinvData) dashboardFilterData();
  }, [
    forecastData,
    revinvData,
    selectedBranch,
    selectedDepartment,
    purchaseTagRawData,
    purchaseFreqRawData,
    wsr12RawData,
    atRiskData,
    leadRawData,
    lostRawData,
    gainRawData,
    benchRawData,
    sseRawData,
    sseSumRawData,
  ]);

  return (
    <Grid>
      <Grid
        container
        sx={{
          padding: "0px 20px 10px 20px",
        }}
      >
        <Grid container sx={{}}>
          <Grid container item xs={4} sx={{ padding: "10px" }}>
            <Grid
              item
              xs={12}
              sx={{
                backgroundColor: "#38B54A",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "5px 0px 5px 20px",
                borderRadius: "5px 5px 0px 0px",
                border: "1px solid grey",
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#FFFFFF", fontWeight: "bold" }}
              >
                Active Account 12mm
              </Typography>
            </Grid>
            {activeAccountLoading ? (
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "200px",
                  border: "1px solid grey",
                }}
              >
                <MiniSpinner />
              </Grid>
            ) : (
              <Grid
                container
                item
                xs={12}
                sx={{
                  padding: "10px 20px 10px 20px",
                  border: "1px solid grey",
                  backgroundColor: "rgba(181, 181, 195, 0.5)",
                  gap: 2,
                }}
              >
                <Grid
                  container
                  item
                  xs={12}
                  sx={{
                    backgroundColor: "#FFFFFF",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <Grid
                    container
                    item
                    xs={2}
                    sx={{
                      justifyContent: "center",
                      backgroundColor: "rgba(176, 215, 255, 0.3)",
                      borderRadius: "5px",
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundImage: `url(${graph})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        color: "#fff",
                        height: "60px",
                        width: "60px",
                      }}
                    ></Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={5}
                    sx={{
                      padding: "0px 0px 0px 20px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                      {dashboardActiveAccount}
                    </Typography>
                    <Typography>Current</Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={5}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor:
                          Number(dashboardGrowthAccount) > 0
                            ? "rgba(235, 253, 243, 1)"
                            : "rgba(254, 236, 238, 1)",
                        height: "40px",
                        alignItems: "center",
                        padding: "10px",
                        gap: 1,
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      {Number(dashboardGrowthAccount) > 0 ? (
                        <TrendingUpIcon
                          sx={{ color: "rgba(56, 181, 74, 1)" }}
                        />
                      ) : (
                        <TrendingDownIcon
                          sx={{ color: "rgba(250, 3, 3, 0.8)" }}
                        />
                      )}
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: "bold",
                          color:
                            Number(dashboardGrowthAccount) > 0
                              ? "rgba(56, 181, 74, 1)"
                              : "rgba(250, 3, 3, 0.8)",
                        }}
                      >
                        {Number(dashboardGrowthAccount) > 0
                          ? `+${dashboardGrowthAccount}`
                          : dashboardGrowthAccount}
                        %
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sx={{
                    backgroundColor: "#FFFFFF",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <Grid
                    container
                    item
                    xs={2}
                    sx={{
                      justifyContent: "center",
                      backgroundColor: "rgba(255, 168, 0, 0.19)",
                      borderRadius: "5px",
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundImage: `url(${chart})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        height: "60px",
                        width: "60px",
                      }}
                    ></Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={5}
                    sx={{
                      padding: "0px 0px 0px 20px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                      {dashboardForecastActiveAccount}
                    </Typography>
                    <Typography>Forecast</Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={5}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor:
                          Number(dashboardForecastGrowthAccount) > 0
                            ? "rgba(235, 253, 243, 1)"
                            : "rgba(254, 236, 238, 1)",
                        height: "40px",
                        alignItems: "center",
                        padding: "10px",
                        gap: 1,
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      {Number(dashboardForecastGrowthAccount) > 0 ? (
                        <TrendingUpIcon
                          sx={{ color: "rgba(56, 181, 74, 1)" }}
                        />
                      ) : (
                        <TrendingDownIcon
                          sx={{ color: "rgba(250, 3, 3, 0.8)" }}
                        />
                      )}
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: "bold",
                          color:
                            Number(dashboardForecastGrowthAccount) > 0
                              ? "rgba(56, 181, 74, 1)"
                              : "rgba(250, 3, 3, 0.8)",
                        }}
                      >
                        {Number(dashboardForecastGrowthAccount) > 0
                          ? `+${dashboardForecastGrowthAccount}`
                          : dashboardForecastGrowthAccount}
                        %
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid container item xs={4} sx={{ padding: "10px" }}>
            <Grid
              item
              xs={12}
              sx={{
                backgroundColor: "#38B54A",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "5px 0px 5px 20px",
                borderRadius: "5px 5px 0px 0px",
                border: "1px solid grey",
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#FFFFFF", fontWeight: "bold" }}
              >
                Transaction 12mm
              </Typography>
            </Grid>
            {activeAccountLoading ? (
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "200px",
                  border: "1px solid grey",
                }}
              >
                <MiniSpinner />
              </Grid>
            ) : (
              <Grid
                container
                item
                xs={12}
                sx={{
                  padding: "10px 20px 10px 20px",
                  border: "1px solid grey",
                  backgroundColor: "rgba(181, 181, 195, 0.5)",
                  gap: 2,
                }}
              >
                <Grid
                  container
                  item
                  xs={12}
                  sx={{
                    // border: "1px solid grey",
                    backgroundColor: "#FFFFFF",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <Grid
                    container
                    item
                    xs={2}
                    sx={{
                      justifyContent: "center",
                      backgroundColor: "rgba(176, 215, 255, 0.3)",
                      borderRadius: "5px",
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundImage: `url(${graph})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        color: "#fff",
                        height: "60px",
                        width: "60px",
                      }}
                    ></Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={5}
                    sx={{
                      padding: "0px 0px 0px 20px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                      {dashboardTransactionInvoiceActive}
                    </Typography>
                    <Typography>Current</Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={5}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor:
                          Number(dashboardTransactionInvoiceGrowth) > 0
                            ? "rgba(235, 253, 243, 1)"
                            : "rgba(254, 236, 238, 1)",
                        height: "40px",
                        alignItems: "center",
                        padding: "10px",
                        gap: 1,
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      {Number(dashboardTransactionInvoiceGrowth) > 0 ? (
                        <TrendingUpIcon
                          sx={{ color: "rgba(56, 181, 74, 1)" }}
                        />
                      ) : (
                        <TrendingDownIcon
                          sx={{ color: "rgba(250, 3, 3, 0.8)" }}
                        />
                      )}
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: "bold",
                          color:
                            Number(dashboardTransactionInvoiceGrowth) > 0
                              ? "rgba(56, 181, 74, 1)"
                              : "rgba(250, 3, 3, 0.8)",
                        }}
                      >
                        {Number(dashboardTransactionInvoiceGrowth) > 0
                          ? `+${dashboardTransactionInvoiceGrowth}`
                          : dashboardTransactionInvoiceGrowth}
                        %
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sx={{
                    backgroundColor: "#FFFFFF",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <Grid
                    container
                    item
                    xs={2}
                    sx={{
                      justifyContent: "center",
                      backgroundColor: "rgba(255, 168, 0, 0.19)",
                      borderRadius: "5px",
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundImage: `url(${chart})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        height: "60px",
                        width: "60px",
                      }}
                    ></Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={5}
                    sx={{
                      padding: "0px 0px 0px 20px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                      {dashboardForecastInvoiceActive}
                    </Typography>
                    <Typography>Forecast</Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={5}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor:
                          Number(dashboardForecastInvoiceGrowth) > 0
                            ? "rgba(235, 253, 243, 1)"
                            : "rgba(254, 236, 238, 1)",
                        height: "40px",
                        alignItems: "center",
                        padding: "10px",
                        gap: 1,
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      {Number(dashboardForecastInvoiceGrowth) > 0 ? (
                        <TrendingUpIcon
                          sx={{ color: "rgba(56, 181, 74, 1)" }}
                        />
                      ) : (
                        <TrendingDownIcon
                          sx={{ color: "rgba(250, 3, 3, 0.8)" }}
                        />
                      )}
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: "bold",
                          color:
                            Number(dashboardForecastInvoiceGrowth) > 0
                              ? "rgba(56, 181, 74, 1)"
                              : "rgba(250, 3, 3, 0.8)",
                        }}
                      >
                        {Number(dashboardForecastInvoiceGrowth) > 0
                          ? `+${dashboardForecastInvoiceGrowth}`
                          : dashboardForecastInvoiceGrowth}
                        %
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid container item xs={4} sx={{ padding: "10px" }}>
            <Grid
              item
              xs={12}
              sx={{
                backgroundColor: "#38B54A",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "5px 0px 5px 20px",
                borderRadius: "5px 5px 0px 0px",
                border: "1px solid grey",
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#FFFFFF", fontWeight: "bold" }}
              >
                Revenue 12mm
              </Typography>
            </Grid>
            {activeAccountLoading ? (
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "200px",
                  border: "1px solid grey",
                }}
              >
                <MiniSpinner />
              </Grid>
            ) : (
              <Grid
                container
                item
                xs={12}
                sx={{
                  padding: "10px 20px 10px 20px",
                  border: "1px solid grey",
                  backgroundColor: "rgba(181, 181, 195, 0.5)",
                  gap: 2,
                }}
              >
                <Grid
                  container
                  item
                  xs={12}
                  sx={{
                    // border: "1px solid grey",
                    backgroundColor: "#FFFFFF",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <Grid
                    container
                    item
                    xs={2}
                    sx={{
                      justifyContent: "center",
                      backgroundColor: "rgba(176, 215, 255, 0.3)",
                      borderRadius: "5px",
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundImage: `url(${graph})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        color: "#fff",
                        height: "60px",
                        width: "60px",
                      }}
                    ></Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={5}
                    sx={{
                      padding: "0px 0px 0px 20px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                      $ {dashboardRevenueActiveAccount}
                    </Typography>
                    <Typography>Current</Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={5}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor:
                          Number(dashboardRevenueAccountGrowth) > 0
                            ? "rgba(235, 253, 243, 1)"
                            : "rgba(254, 236, 238, 1)",
                        height: "40px",
                        alignItems: "center",
                        padding: "10px",
                        gap: 1,
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      {Number(dashboardRevenueAccountGrowth) > 0 ? (
                        <TrendingUpIcon
                          sx={{ color: "rgba(56, 181, 74, 1)" }}
                        />
                      ) : (
                        <TrendingDownIcon
                          sx={{ color: "rgba(250, 3, 3, 0.8)" }}
                        />
                      )}
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: "bold",
                          color:
                            Number(dashboardRevenueAccountGrowth) > 0
                              ? "rgba(56, 181, 74, 1)"
                              : "rgba(250, 3, 3, 0.8)",
                        }}
                      >
                        {Number(dashboardRevenueAccountGrowth) > 0
                          ? `+${dashboardRevenueAccountGrowth}`
                          : dashboardRevenueAccountGrowth}
                        %
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sx={{
                    backgroundColor: "#FFFFFF",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <Grid
                    container
                    item
                    xs={2}
                    sx={{
                      justifyContent: "center",
                      backgroundColor: "rgba(255, 168, 0, 0.19)",
                      borderRadius: "5px",
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundImage: `url(${chart})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        height: "60px",
                        width: "60px",
                      }}
                    ></Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={5}
                    sx={{
                      padding: "0px 0px 0px 20px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                      $ {dashboardForecastRevenue}
                    </Typography>
                    <Typography>Forecast</Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={5}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor:
                          Number(dashboardForecastRevenueGrowth) > 0
                            ? "rgba(235, 253, 243, 1)"
                            : "rgba(254, 236, 238, 1)",
                        height: "40px",
                        alignItems: "center",
                        padding: "10px",
                        gap: 1,
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      {Number(dashboardForecastRevenueGrowth) > 0 ? (
                        <TrendingUpIcon
                          sx={{ color: "rgba(56, 181, 74, 1)" }}
                        />
                      ) : (
                        <TrendingDownIcon
                          sx={{ color: "rgba(250, 3, 3, 0.8)" }}
                        />
                      )}
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: "bold",
                          color:
                            Number(dashboardForecastRevenueGrowth) > 0
                              ? "rgba(56, 181, 74, 1)"
                              : "rgba(250, 3, 3, 0.8)",
                        }}
                      >
                        {Number(dashboardForecastRevenueGrowth) > 0
                          ? `+${dashboardForecastRevenueGrowth}`
                          : dashboardForecastRevenueGrowth}
                        %
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid container item xs={4} sx={{ padding: "10px" }}>
            <Grid
              container
              item
              xs={7}
              sx={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "rgba(0, 70, 128, 1)",
                gap: 3,
                borderRadius: "10px",
                padding: "10px",
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 3,
                }}
              >
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundImage: `url(${purchase})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    color: "#fff",
                    height: "60px",
                    width: "60px",
                  }}
                ></Grid>
                <Typography
                  variant="h5"
                  sx={{ color: "rgba(218, 218, 218, 1)" }}
                >
                  PURCHASE
                </Typography>
              </Grid>
              {purchaseDistributionLoading ? (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100px",
                  }}
                >
                  <MiniSpinner />
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{ fontWeight: "bold", color: "rgba(90, 238, 158, 1)" }}
                  >
                    {dashboardCustomerPurchase}%
                  </Typography>
                  <Typography sx={{ color: "rgba(218, 218, 218, 1)" }}>
                    Customer purchase in
                  </Typography>
                  <Typography sx={{ color: "rgba(218, 218, 218, 1)" }}>
                    PAST 4 WEEKS
                  </Typography>
                </Grid>
              )}
              {purchaseDistributionLoading ? (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100px",
                  }}
                >
                  <MiniSpinner />
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{ fontWeight: "bold", color: "rgba(90, 238, 158, 1)" }}
                  >
                    {dashboardCustomerFrquency}%
                  </Typography>
                  <Typography sx={{ color: "rgba(218, 218, 218, 1)" }}>
                    Customer purchase
                  </Typography>
                  <Typography sx={{ color: "rgba(218, 218, 218, 1)" }}>
                    MORE THAN 5X
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              item
              xs={5}
              sx={{
                gap: 2,
                paddingLeft: "10px",
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  backgroundColor: "rgba(68, 185, 85, 1)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  borderRadius: "10px",
                  padding: "10px",
                }}
              >
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundImage: `url(${twohands})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    color: "#fff",
                    height: "60px",
                    width: "60px",
                  }}
                ></Grid>
                <Typography
                  variant="h6"
                  sx={{ color: "rgba(255, 255, 255, 1)" }}
                >
                  RETENTION
                </Typography>
                {retentionRateLoading ? (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "40px",
                    }}
                  >
                    <MiniSpinner />
                  </Grid>
                ) : (
                  <Typography
                    variant="h4"
                    sx={{ color: "rgba(255, 255, 255, 1)", fontWeight: "bold" }}
                  >
                    {dashboardRetentionRate}%
                  </Typography>
                )}
                <Typography sx={{ color: "rgba(255, 255, 255, 1)" }}>
                  3 month average
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  backgroundColor: "rgba(241, 158, 0, 1)",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "10px",
                  padding: "10px",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundImage: `url(${npsscore})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    color: "#fff",
                    height: "60px",
                    width: "60px",
                  }}
                ></Grid>
                <Typography
                  variant="h6"
                  sx={{ color: "rgba(255, 255, 255, 1)" }}
                >
                  NPS SCORE
                </Typography>
                {npsScoreLoading ? (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "40px",
                    }}
                  >
                    <MiniSpinner />
                  </Grid>
                ) : (
                  <Typography
                    variant="h4"
                    sx={{ color: "rgba(255, 255, 255, 1)", fontWeight: "bold" }}
                  >
                    {dashboardNPS}
                  </Typography>
                )}
                <Typography sx={{ color: "rgba(255, 255, 255, 1)" }}>
                  3 month average
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={8}>
            <Grid
              container
              item
              xs={4}
              sx={{
                padding: "10px",
              }}
            >
              <Grid
                container
                sx={{
                  padding: "10px",
                  gap: 1,
                  backgroundColor: "#FFFFFF",
                  borderRadius: "10px",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    alignItems: "center",
                  }}
                >
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      backgroundImage: `url(${error})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      color: "#fff",
                      height: "50px",
                      width: "50px",
                    }}
                  ></Grid>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    At Risk
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  sx={{
                    backgroundColor: "rgba(56, 181, 74, 1)",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                    // justifyContent: "center",
                  }}
                >
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <IconButton
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
                      size="large"
                    >
                      <PercentIcon sx={{ color: "rgba(255, 255, 255, 1)" }} />
                    </IconButton>
                  </Grid>
                  <Grid>
                    {atRiskLoading ? (
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <MiniSpinner />
                      </Grid>
                    ) : (
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: "bold",
                          color: "rgba(255, 255, 255, 1)",
                        }}
                      >
                        {dashboardAtRiskStableCustomer}%
                      </Typography>
                    )}
                    <Typography sx={{ color: "rgba(255, 255, 255, 1)" }}>
                      stable
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  sx={{
                    backgroundColor: "rgba(0, 70, 128, 1)",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <IconButton
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.2)" }}
                      size="large"
                    >
                      <PercentIcon sx={{ color: "rgba(255, 255, 255, 1)" }} />
                    </IconButton>
                  </Grid>
                  <Grid>
                    {atRiskLoading ? (
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <MiniSpinner />
                      </Grid>
                    ) : (
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: "bold",
                          color: "rgba(255, 75, 85, 1)",
                        }}
                      >
                        {dashboardAtRiskLCustomer}%
                      </Typography>
                    )}
                    <Typography sx={{ color: "rgba(255, 255, 255, 1)" }}>
                      Risk1
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sx={{
                    backgroundColor: "rgba(0, 70, 128, 1)",
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <IconButton
                      sx={{ backgroundColor: "rgba(255, 75, 85, 0.2)" }}
                      size="large"
                    >
                      <PercentIcon sx={{ color: "rgba(255, 75, 85, 1)" }} />
                    </IconButton>
                  </Grid>
                  <Grid>
                    {atRiskLoading ? (
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <MiniSpinner />
                      </Grid>
                    ) : (
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: "bold",
                          color: "rgba(255, 75, 85, 1)",
                        }}
                      >
                        {dashboardAtRiskUCustomer}%
                      </Typography>
                    )}
                    <Typography sx={{ color: "rgba(255, 255, 255, 1)" }}>
                      Risk2
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={4} sx={{ padding: "10px" }}>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Grid
                  sx={{
                    backgroundColor: "rgba(0, 70, 128, 1)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "5px 5px 0px 0px",
                    padding: "10px",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{ color: "#FFFFFF", fontWeight: "bold" }}
                  >
                    Retention Leads
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    padding: "10px 20px 10px 20px",
                    border: "1px solid grey",
                    backgroundColor: "rgba(181, 181, 195, 0.5)",
                    gap: 3,
                  }}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#FFFFFF",
                      padding: "10px",
                      borderRadius: "5px",
                      gap: 1,
                    }}
                  >
                    <Typography>9-12:</Typography>
                    {retentionLoading ? (
                      <MiniSpinner />
                    ) : (
                      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        {dashboardCustomerLead}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      backgroundColor: "#FFFFFF",
                      padding: "10px",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1,
                    }}
                  >
                    <Typography>Lost:</Typography>
                    {retentionLoading ? (
                      <MiniSpinner />
                    ) : (
                      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        {dashboardCustomerLost}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      backgroundColor: "#FFFFFF",
                      padding: "10px",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1,
                    }}
                  >
                    <Typography>Gain:</Typography>
                    {retentionLoading ? (
                      <MiniSpinner />
                    ) : (
                      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        {dashboardCustomerGain}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={4} sx={{ padding: "10px" }}>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Grid
                  sx={{
                    backgroundColor: "rgba(0, 70, 128, 1)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "5px 5px 0px 0px",
                    border: "1px solid grey",
                    padding: "10px",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{ color: "#FFFFFF", fontWeight: "bold" }}
                  >
                    Emails Report
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sx={{
                    padding: "10px 20px 10px 20px",
                    border: "1px solid grey",
                    backgroundColor: "rgba(181, 181, 195, 0.5)",
                    gap: 2,
                  }}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#FFFFFF",
                      padding: "10px",
                      borderRadius: "5px",
                      gap: 1,
                    }}
                  >
                    <Typography>Customer Email %:</Typography>
                    {emailReportsLoading ? (
                      <MiniSpinner />
                    ) : (
                      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        {dashboardCEM}%
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      backgroundColor: "#FFFFFF",
                      padding: "10px",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1,
                    }}
                  >
                    <Typography>Email Value:</Typography>
                    {emailReportsLoading ? (
                      <MiniSpinner />
                    ) : (
                      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        {marketCustData}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      backgroundColor: "#FFFFFF",
                      padding: "10px",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1,
                    }}
                  >
                    <Typography>Open Rate:</Typography>
                    {emailReportsLoading ? (
                      <MiniSpinner />
                    ) : (
                      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        {dashboardSSCR}%
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{
                      backgroundColor: "#FFFFFF",
                      padding: "10px",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 1,
                    }}
                  >
                    <Typography>Click Rate:</Typography>
                    {emailReportsLoading ? (
                      <MiniSpinner />
                    ) : (
                      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        {dashboardSSM}%
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AnalyticDashboard;
