import React, { SyntheticEvent, useEffect, useState } from "react";
import { BarChart } from "@mui/x-charts";
import {
  Table,
  TableBody,
  TableCell,
  Button,
  Grid,
  TableContainer,
  Typography,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useAppSelector } from "../../../../hooks/hooks";
import {
  GetArray,
  GetObject,
  WithCommas,
  exportToExcel,
  exportToExcelOther,
} from "../../../../utils/utils";
import MiniSpinner from "../../../reusableComponents/miniSpinner/miniSpinner";

const SalesMarketing = () => {
  const {
    selectedClient,
    selectedBranch,
    selectedDepartment,
    verifiedSunRawData,
    formFillSumRawData,
    gainLost6RawData,
    gainLost12RawData,
    gainLost36RawData,
    revinvData,
    marketCustRawData,
    prcustRawData,
    prrevRawData,
    salstbRawData,
    salemRawData,
    prospectLeadsReportsLoading,
    lastPurchaseCustomerLeadsLoading,
    marketByCustomerLoading,
    productByCustomerCountLoading,
    rankByStableAccountLoading,
    leadRawData,
    lostRawData,
    gainRawData,
    saleLeadRawData,
  } = useAppSelector((state: any) => state.portalManagement);

  const [
    salesMarketingProsperLeadVerifiedInfo,
    setSalesMarketingProsperLeadVerifiedInfo,
  ] = useState({
    monthYear: "",
    accountM: "",
    account6M: "",
    account12M: "",
    constactM: "",
    constact6M: "",
    constact12M: "",
    emailM: "",
    email6M: "",
    email12M: "",
    accountTotal: "",
    emailTotal: "",
    countactTotal: "",
  });

  const [
    salesMarketingProsperLeadFormFillInfo,
    setSalesMarketingProsperLeadFormFillInfo,
  ] = useState({
    monthYear: "",
    formFillM: "",
    formFill6M: "",
    formFill12M: "",
  });

  const [salesMarketingMarketCustTable, setsalesMarketingMarketCustTable] =
    useState([]);

  const [
    salesMarketingProductCustomerChartdata,
    setsalesMarketingProductCustomerChartdata,
  ] = useState([]);

  const [
    salesMarketingProductCustomerChartLabel,
    setsalesMarketingProductCustomerChartLabel,
  ] = useState([]);
  const [
    salesMarketingProductRevenueChartLabel,
    setsalesMarketingProductRevenueChartLabel,
  ] = useState([]);
  const [
    salesMarketingProductRevenueChartData,
    setsalesMarketingProductRevenueChartData,
  ] = useState([]);

  const [
    salesMarketingStableAccountChartData,
    setsalesMarketingStableAccountChartData,
  ] = useState([]);
  const [
    salesMarketingStableAccountChartLabel,
    setsalesMarketingStableAccountChartLabel,
  ] = useState([]);
  const [salesMarketingEmailChartData, setsalesMarketingEmailChartData] =
    useState([]);
  const [salesMarketingEmailChartLabel, setsalesMarketingEmailChartLabel] =
    useState([]);

  const [activeCustomerCount, setActiveCustomerCount] =
    useState("CUSTOMER_COUNT");

  const handleCustomerCountNav = (value: string) => {
    setActiveCustomerCount(value);
  };
  const [activeSMRevenueMonth, setActiveSMRevenueMonth] =
    useState("STABLE_ACCOUNT");

  const [saleleadDownloadData, setSaleleadDownloadData] = useState<any[]>([]);

  const handleSMRevenueMonthNav = (value: string) => {
    setActiveSMRevenueMonth(value);
  };

  const [salesMarketingLeadsCustomers, setsalesMarketingLeadsCustomers] =
    useState({
      cust_lead: "",
      lead_purchase: "",
      lead_rev: "",
    });
  const [salesMarketingLostCustomers, setsalesMarketingLostCustomers] =
    useState({ cust_lost: "", lost_purchase: "", lost_rev: "" });
  const [salesMarketingGainedCustomers, setsalesMarketingGainedCustomers] =
    useState({ cust_gain: "", gain_purchase: "", gain_rev: "" });

  const createData = (
    program: string,
    transaction: string,
    revenue: string,
    change: string,
    cost: string,
    roi: string
  ) => {
    return { program, transaction, revenue, change, cost, roi };
  };

  const periodOption = [
    { label: "6 months", value: "6 months" },
    { label: "12 months", value: "12 months" },
    { label: "24 months", value: "24 months" },
    { label: "36 months", value: "36 months" },
  ];

  const [selectedPeriod, setSelectedPeriod] = useState<any>({
    label: "6 months",
    value: "6 months",
  });

  const handlePeriodChange = (selection: any) => {
    if (selection) {
      setSelectedPeriod(selection);
    } else
      setSelectedPeriod({
        label: "6 months",
        value: "6 months",
      });
  };

  const salesMarketingFilterData = () => {
    if (verifiedSunRawData) {
      setSalesMarketingProsperLeadVerifiedInfo({
        monthYear: verifiedSunRawData.month_year
          ? verifiedSunRawData.month_year
          : "",
        accountM: verifiedSunRawData.account_m
          ? verifiedSunRawData.account_m
          : "",
        account6M: verifiedSunRawData.account_6m
          ? verifiedSunRawData.account_6m
          : "",
        account12M: verifiedSunRawData.account_12m
          ? verifiedSunRawData.account_12m
          : "",
        constactM: verifiedSunRawData.contact_m
          ? verifiedSunRawData.contact_m
          : "",
        constact6M: verifiedSunRawData.contact_6m
          ? verifiedSunRawData.contact_6m
          : "",
        constact12M: verifiedSunRawData.contact_12m
          ? verifiedSunRawData.contact_12m
          : "",
        emailM: verifiedSunRawData.email_m ? verifiedSunRawData.email_m : "",
        email6M: verifiedSunRawData.email_6m ? verifiedSunRawData.email_6m : "",
        email12M: verifiedSunRawData.email_12m
          ? verifiedSunRawData.email_12m
          : "",
        accountTotal: verifiedSunRawData.account_total
          ? verifiedSunRawData.account_total
          : "",
        emailTotal: verifiedSunRawData.contact_total
          ? verifiedSunRawData.contact_total
          : "",
        countactTotal: verifiedSunRawData.email_total
          ? verifiedSunRawData.email_total
          : "",
      });
    } else {
      setSalesMarketingProsperLeadVerifiedInfo({
        monthYear: "",
        accountM: "",
        account6M: "",
        account12M: "",
        constactM: "",
        constact6M: "",
        constact12M: "",
        emailM: "",
        email6M: "",
        email12M: "",
        accountTotal: "",
        emailTotal: "",
        countactTotal: "",
      });
    }

    if (formFillSumRawData) {
      setSalesMarketingProsperLeadFormFillInfo({
        monthYear: formFillSumRawData.month_year
          ? formFillSumRawData.month_year
          : "",
        formFillM: formFillSumRawData.ff_m ? formFillSumRawData.ff_m : "",
        formFill6M: formFillSumRawData.ff_6m ? formFillSumRawData.ff_6m : "",
        formFill12M: formFillSumRawData.ff_12m ? formFillSumRawData.ff_12m : "",
      });
    } else {
      setSalesMarketingProsperLeadFormFillInfo({
        monthYear: "",
        formFillM: "",
        formFill6M: "",
        formFill12M: "",
      });
    }

    if (marketCustRawData) {
      if (Object.keys(marketCustRawData).length !== 0) {
        setsalesMarketingMarketCustTable(
          marketCustRawData[selectedBranch.value][selectedDepartment.value][
            selectedPeriod.value
          ]
            ? marketCustRawData[selectedBranch.value][selectedDepartment.value][
                selectedPeriod.value
              ]
            : []
        );
      } else {
        setsalesMarketingMarketCustTable([]);
      }
    }

    if (prcustRawData) {
      const prcustFilter = GetObject(
        GetArray(prcustRawData).filter(
          (item: any) =>
            item.BRANCH === selectedBranch.value &&
            item.DEPARTMENT === selectedDepartment.value
        )
      );

      if (Object.keys(prcustFilter).length !== 0) {
        setsalesMarketingProductCustomerChartdata(prcustFilter.customer_count);
        setsalesMarketingProductCustomerChartLabel(prcustFilter.product_cons);
      } else {
        setsalesMarketingProductCustomerChartdata([]);
        setsalesMarketingProductCustomerChartLabel([]);
      }
    }

    if (prrevRawData) {
      const prrevFilter = GetObject(
        GetArray(prrevRawData).filter(
          (item: any) =>
            item.BRANCH === selectedBranch.value &&
            item.DEPARTMENT === selectedDepartment.value
        )
      );

      if (Object.keys(prrevFilter).length !== 0) {
        setsalesMarketingProductRevenueChartData(prrevFilter.revenue);
        setsalesMarketingProductRevenueChartLabel(prrevFilter.product_cons);
      } else {
        setsalesMarketingProductRevenueChartData([]);
        setsalesMarketingProductRevenueChartLabel([]);
      }
    }

    if (salstbRawData) {
      const salstbFilter = GetObject(
        GetArray(salstbRawData).filter(
          (item: any) => item.BRANCH === selectedBranch.value
        )
      );

      if (Object.keys(salstbFilter).length !== 0) {
        setsalesMarketingStableAccountChartData(salstbFilter.STABLE_PERCENT);
        setsalesMarketingStableAccountChartLabel(salstbFilter.SALESMAN);
      } else {
        setsalesMarketingStableAccountChartData([]);
        setsalesMarketingStableAccountChartLabel([]);
      }
    }

    if (salemRawData) {
      const salemFilter = GetObject(
        GetArray(salemRawData).filter(
          (item: any) => item.BRANCH === selectedBranch.value
        )
      );

      if (Object.keys(salemFilter).length !== 0) {
        setsalesMarketingEmailChartData(salemFilter.EMAIL_PERCENT);
        setsalesMarketingEmailChartLabel(salemFilter.SALESMAN);
      } else {
        setsalesMarketingEmailChartData([]);
        setsalesMarketingEmailChartLabel([]);
      }
    }

    if (leadRawData) {
      const lprCustLeadsFilter = GetObject(
        GetArray(leadRawData).filter(
          (item: any) =>
            item.BRANCH === selectedBranch.value &&
            item.PRODUCT_TYPE_ALL === selectedDepartment.value
        )
      );

      if (Object.keys(lprCustLeadsFilter).length !== 0) {
        setsalesMarketingLeadsCustomers({
          cust_lead: WithCommas(
            lprCustLeadsFilter.cust_lead &&
              lprCustLeadsFilter.cust_lead.length >= 1
              ? lprCustLeadsFilter.cust_lead[0]
              : "-"
          ),
          lead_purchase: WithCommas(
            lprCustLeadsFilter.lead_purchase &&
              lprCustLeadsFilter.lead_purchase.length >= 1
              ? lprCustLeadsFilter.lead_purchase[0]
              : "-"
          ),
          lead_rev: WithCommas(
            lprCustLeadsFilter.lead_rev &&
              lprCustLeadsFilter.lead_rev.length >= 1
              ? lprCustLeadsFilter.lead_rev[0]
              : "-"
          ),
        });
      } else {
        setsalesMarketingLeadsCustomers({
          cust_lead: "-",
          lead_purchase: "-",
          lead_rev: "-",
        });
      }
    }

    if (lostRawData) {
      const lprCustLostFilter = GetObject(
        GetArray(lostRawData).filter(
          (item: any) =>
            item.BRANCH === selectedBranch.value &&
            item.PRODUCT_TYPE_ALL === selectedDepartment.value
        )
      );

      if (Object.keys(lprCustLostFilter).length !== 0) {
        setsalesMarketingLostCustomers({
          cust_lost:
            lprCustLostFilter.cust_lead &&
            lprCustLostFilter.cust_lead.length >= 1
              ? lprCustLostFilter.cust_lead[0]
              : "-",
          lost_purchase: WithCommas(
            lprCustLostFilter.lead_purchase &&
              lprCustLostFilter.lead_purchase.length >= 1
              ? lprCustLostFilter.lead_purchase[0]
              : "-"
          ),
          lost_rev: WithCommas(
            lprCustLostFilter.lead_rev && lprCustLostFilter.lead_rev.length >= 1
              ? lprCustLostFilter.lead_rev[0]
              : "-"
          ),
        });
      } else {
        setsalesMarketingLostCustomers({
          cust_lost: "-",
          lost_purchase: "-",
          lost_rev: "-",
        });
      }
    }
    if (gainRawData) {
      const lprCustGainFilter = GetObject(
        GetArray(gainRawData).filter(
          (item: any) =>
            item.BRANCH === selectedBranch.value &&
            item.PRODUCT_TYPE_ALL === selectedDepartment.value
        )
      );

      if (Object.keys(lprCustGainFilter).length !== 0) {
        setsalesMarketingGainedCustomers({
          cust_gain:
            lprCustGainFilter.cust_lead &&
            lprCustGainFilter.cust_lead.length >= 1
              ? lprCustGainFilter.cust_lead[0]
              : "-",
          gain_purchase: WithCommas(
            lprCustGainFilter.lead_purchase &&
              lprCustGainFilter.lead_purchase.length >= 1
              ? lprCustGainFilter.lead_purchase[0]
              : "-"
          ),
          gain_rev: WithCommas(
            lprCustGainFilter.lead_rev && lprCustGainFilter.lead_rev.length >= 1
              ? lprCustGainFilter.lead_rev[0]
              : "-"
          ),
        });
      } else {
        setsalesMarketingGainedCustomers({
          cust_gain: "-",
          gain_purchase: "-",
          gain_rev: "-",
        });
      }
    }

    if (saleLeadRawData) {
      const filteredSaleLeadRawData =
        saleLeadRawData[selectedBranch.value][selectedDepartment.value];
      setSaleleadDownloadData(filteredSaleLeadRawData);
    }
  };

  const handleLeadDowload = () => {
    if (saleleadDownloadData && saleleadDownloadData.length > 0)
      exportToExcelOther(saleleadDownloadData, "Sale Lead Info");
  };

  useEffect(() => {
    salesMarketingFilterData();
  }, [
    selectedBranch,
    selectedDepartment,
    verifiedSunRawData,
    formFillSumRawData,
    gainLost6RawData,
    gainLost12RawData,
    gainLost36RawData,
    revinvData,
    marketCustRawData,
    prcustRawData,
    prrevRawData,
    salstbRawData,
    salemRawData,
    leadRawData,
    lostRawData,
    gainRawData,
  ]);
  return (
    <Grid container sx={{}}>
      <Grid
        container
        sx={{
          padding: "0px 10px 10px 10px",
        }}
      >
        <Grid container item xs={12} sx={{ padding: "10px" }}>
          <Grid container item xs={6} sx={{ padding: "10px" }}>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Grid
                sx={{
                  backgroundColor: "#38B54A",
                  display: "flex",
                  // justifyContent: "center",
                  padding: "5px 0px 5px 20px",
                  alignItems: "center",
                  borderRadius: "5px 5px 0px 0px",
                  border: "1px solid grey",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ color: "#FFFFFF", fontWeight: "bold" }}
                >
                  Prospect Leads, programs and Tools Reports
                </Typography>
              </Grid>
              {prospectLeadsReportsLoading ? (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "300px",
                    border: "1px solid grey",
                    width: "100%",
                  }}
                >
                  <MiniSpinner />
                </Grid>
              ) : (
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px 20px 10px 20px",
                    border: "1px solid grey",
                    backgroundColor: "#FFFFFF",
                    gap: 2,
                    height: "100%",
                  }}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    sx={{ padding: "0px 10% 0px 10%" }}
                  >
                    <Grid container item xs={12} sx={{}}>
                      <Grid container item xs={4} sx={{ padding: "10px" }}>
                        <Paper
                          elevation={2}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            borderRadius: "10px",
                            padding: "10px",
                            gap: 3,
                            alignItems: "center",
                          }}
                        >
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                color: "rgba(0, 0, 0, 1)",
                              }}
                            >
                              VERIFIED ACCOUNT
                            </Typography>
                          </Grid>
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "10px",
                                color: "rgba(135, 135, 135, 1)",
                              }}
                            >
                              Total
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "bold",
                                color: "rgba(0, 70, 128, 1)",
                              }}
                            >
                              {
                                salesMarketingProsperLeadVerifiedInfo.accountTotal
                              }
                            </Typography>
                          </Grid>
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "10px",
                                color: "rgba(135, 135, 135, 1)",
                              }}
                            >
                              {salesMarketingProsperLeadVerifiedInfo.monthYear}
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "bold",
                                color: "rgba(49, 144, 242, 1)",
                              }}
                            >
                              {salesMarketingProsperLeadVerifiedInfo.accountM}
                            </Typography>
                          </Grid>
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "10px",
                                color: "rgba(135, 135, 135, 1)",
                              }}
                            >
                              6 Month
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "bold",
                                color: "rgba(49, 144, 242, 1)",
                              }}
                            >
                              {salesMarketingProsperLeadVerifiedInfo.account6M}
                            </Typography>
                          </Grid>
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "10px",
                                color: "rgba(135, 135, 135, 1)",
                              }}
                            >
                              12 Month
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "bold",
                                color: "rgba(49, 144, 242, 1)",
                              }}
                            >
                              {salesMarketingProsperLeadVerifiedInfo.account12M}
                            </Typography>
                          </Grid>
                        </Paper>
                      </Grid>

                      <Grid container item xs={4} sx={{ padding: "10px" }}>
                        <Paper
                          elevation={2}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            borderRadius: "10px",
                            padding: "10px",
                            gap: 3,
                            alignItems: "center",
                          }}
                        >
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                color: "rgba(0, 0, 0, 1)",
                              }}
                            >
                              VERIFIED CONTACT
                            </Typography>
                          </Grid>
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "10px",
                                color: "rgba(135, 135, 135, 1)",
                              }}
                            >
                              Total
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "bold",
                                color: "rgba(0, 70, 128, 1)",
                              }}
                            >
                              {
                                salesMarketingProsperLeadVerifiedInfo.countactTotal
                              }
                            </Typography>
                          </Grid>
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "10px",
                                color: "rgba(135, 135, 135, 1)",
                              }}
                            >
                              {salesMarketingProsperLeadVerifiedInfo.monthYear}
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "bold",
                                color: "rgba(49, 144, 242, 1)",
                              }}
                            >
                              {salesMarketingProsperLeadVerifiedInfo.constactM}
                            </Typography>
                          </Grid>
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "10px",
                                color: "rgba(135, 135, 135, 1)",
                              }}
                            >
                              6 Month
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "bold",
                                color: "rgba(49, 144, 242, 1)",
                              }}
                            >
                              {salesMarketingProsperLeadVerifiedInfo.constact6M}
                            </Typography>
                          </Grid>
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "10px",
                                color: "rgba(135, 135, 135, 1)",
                              }}
                            >
                              12 Month
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "bold",
                                color: "rgba(49, 144, 242, 1)",
                              }}
                            >
                              {
                                salesMarketingProsperLeadVerifiedInfo.constact12M
                              }
                            </Typography>
                          </Grid>
                        </Paper>
                      </Grid>
                      <Grid container item xs={4} sx={{ padding: "10px" }}>
                        <Paper
                          elevation={2}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            borderRadius: "10px",
                            padding: "10px",
                            gap: 3,
                            alignItems: "center",
                          }}
                        >
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                color: "rgba(0, 0, 0, 1)",
                              }}
                            >
                              VERIFIED EMAIL
                            </Typography>
                          </Grid>
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "10px",
                                color: "rgba(135, 135, 135, 1)",
                              }}
                            >
                              Total
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "bold",
                                color: "rgba(0, 70, 128, 1)",
                              }}
                            >
                              {salesMarketingProsperLeadVerifiedInfo.emailTotal}
                            </Typography>
                          </Grid>
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "10px",
                                color: "rgba(135, 135, 135, 1)",
                              }}
                            >
                              {salesMarketingProsperLeadVerifiedInfo.monthYear}
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "bold",
                                color: "rgba(49, 144, 242, 1)",
                              }}
                            >
                              {salesMarketingProsperLeadVerifiedInfo.emailM}
                            </Typography>
                          </Grid>
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "10px",
                                color: "rgba(135, 135, 135, 1)",
                              }}
                            >
                              6 Month
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "bold",
                                color: "rgba(49, 144, 242, 1)",
                              }}
                            >
                              {salesMarketingProsperLeadVerifiedInfo.email6M}
                            </Typography>
                          </Grid>
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "10px",
                                color: "rgba(135, 135, 135, 1)",
                              }}
                            >
                              12 Month
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "bold",
                                color: "rgba(49, 144, 242, 1)",
                              }}
                            >
                              {salesMarketingProsperLeadVerifiedInfo.email12M}
                            </Typography>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container item xs={6} sx={{ padding: "10px" }}>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Grid
                sx={{
                  backgroundColor: "#38B54A",
                  display: "flex",
                  padding: "0px 0px 0px 20px",
                  alignItems: "center",
                  height: "50px",
                  borderRadius: "5px 5px 0px 0px",
                  border: "1px solid grey",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ color: "#FFFFFF", fontWeight: "bold" }}
                >
                  Last Purchase Customer Retention Leads
                </Typography>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px 20px 10px 20px",
                  border: "1px solid grey",
                  backgroundColor: "#FFFFFF",
                  gap: 2,
                  height: "100%",
                }}
              >
                {lastPurchaseCustomerLeadsLoading ? (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "300px",
                      border: "1px solid grey",
                      width: "100%",
                    }}
                  >
                    <MiniSpinner />
                  </Grid>
                ) : (
                  <Grid container item xs={12}>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "right",
                      }}
                    >
                      <Grid
                        sx={{
                          backgroundColor: "rgba(231, 231, 231, 1)",
                          display: "flex",
                          borderRadius: "10px",
                        }}
                      >
                        <Button
                          onClick={() => handleLeadDowload()}
                          variant="outlined"
                          sx={{
                            color: "black",
                            padding: "0px 20px 0px 20px",
                          }}
                        >
                          Dowload Leads
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        <span style={{ fontSize: "20px" }}>
                          Lead Customers:
                        </span>{" "}
                        Who have not Purchased in 9 to 12 Weeks
                      </Typography>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid
                        container
                        item
                        xs={4}
                        sx={{ justifyContent: "center" }}
                      >
                        <Typography sx={{ fontWeight: "bold" }}>
                          # of Customer :{" "}
                          {salesMarketingLeadsCustomers.cust_lead}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={4}
                        sx={{ justifyContent: "center" }}
                      >
                        <Typography sx={{ fontWeight: "bold" }}>
                          Avg Last Purchase :{" "}
                          {salesMarketingLeadsCustomers.lead_purchase}{" "}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={4}
                        sx={{ justifyContent: "center" }}
                      >
                        <Typography sx={{ fontWeight: "bold" }}>
                          Avg Revenue 12mm :{" "}
                          {salesMarketingLeadsCustomers.lead_rev}{" "}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        <span style={{ fontSize: "20px" }}>
                          Lost Customers:
                        </span>{" "}
                        Who have not Purchased in the past 12 months
                      </Typography>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid
                        container
                        item
                        xs={4}
                        sx={{ justifyContent: "center" }}
                      >
                        <Typography sx={{ fontWeight: "bold" }}>
                          # of Customer :{" "}
                          {salesMarketingLostCustomers.cust_lost}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={4}
                        sx={{ justifyContent: "center" }}
                      >
                        <Typography sx={{ fontWeight: "bold" }}>
                          Avg Last Purchase :{" "}
                          {salesMarketingLostCustomers.lost_purchase}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={4}
                        sx={{ justifyContent: "center" }}
                      >
                        <Typography sx={{ fontWeight: "bold" }}>
                          Avg Revenue 12mm :{" "}
                          {salesMarketingLostCustomers.lost_rev}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        <span style={{ fontSize: "20px" }}>
                          Gained Customers:
                        </span>{" "}
                        Who have Purchased in the past 12 months but not in the
                        prior 12 months
                      </Typography>
                    </Grid>
                    <Grid container item xs={12}>
                      <Grid
                        container
                        item
                        xs={4}
                        sx={{ justifyContent: "center" }}
                      >
                        <Typography sx={{ fontWeight: "bold" }}>
                          # of Customer :{" "}
                          {salesMarketingGainedCustomers.cust_gain}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={4}
                        sx={{ justifyContent: "center" }}
                      >
                        <Typography sx={{ fontWeight: "bold" }}>
                          Avg Last Purchase :{" "}
                          {salesMarketingGainedCustomers.gain_purchase}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={4}
                        sx={{ justifyContent: "center" }}
                      >
                        <Typography sx={{ fontWeight: "bold" }}>
                          Avg Revenue 12mm :{" "}
                          {salesMarketingGainedCustomers.gain_rev}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} sx={{ padding: "20px" }}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid
              sx={{
                backgroundColor: "#38B54A",
                display: "flex",
                padding: "5px 0px 5px 20px",
                alignItems: "center",
                borderRadius: "5px 5px 0px 0px",
                border: "1px solid grey",
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#FFFFFF", fontWeight: "bold" }}
              >
                Marketing by Customer ROI
              </Typography>
            </Grid>
            {marketByCustomerLoading ? (
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "300px",
                  border: "1px solid grey",
                  width: "100%",
                }}
              >
                <MiniSpinner />
              </Grid>
            ) : (
              <Grid
                container
                item
                xs={12}
                sx={{
                  padding: "10px 20px 10px 20px",
                  border: "1px solid grey",
                  backgroundColor: "#FFFFFF",
                  gap: 2,
                }}
              >
                <Grid
                  container
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Grid
                    item
                    xs={2}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <FormControl fullWidth>
                      <Autocomplete
                        id="sales_rep"
                        value={selectedPeriod}
                        onChange={(
                          _: SyntheticEvent,
                          newValue: string | null
                        ) => handlePeriodChange(newValue)}
                        options={periodOption}
                        isOptionEqualToValue={(option, newValue) => {
                          return option.id === newValue.id;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Period"
                            placeholder="Period"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container item xs={12} sx={{ height: "300px" }}>
                  <TableContainer component={Paper} sx={{}}>
                    <Table size="small" aria-label="simple table">
                      <TableHead>
                        <TableRow
                          sx={{
                            borderBottom: "none",
                            backgroundColor: "rgba(0, 70, 128, 1)",
                            color: "white",
                          }}
                        >
                          <TableCell
                            sx={{
                              borderBottom: "none",
                              color: "white",
                            }}
                          >
                            Program
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "none",
                              fontWeight: "bold",
                              color: "white",
                            }}
                            align="center"
                          >
                            Transaction Per Customer
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "none",
                              fontWeight: "bold",
                              color: "white",
                            }}
                            align="center"
                          >
                            Revenue Per Customer
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "none",
                              fontWeight: "bold",
                              color: "white",
                            }}
                            align="center"
                          >
                            Change From Not Program
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "none",
                              fontWeight: "bold",
                              color: "white",
                            }}
                            align="center"
                          >
                            Cost per Customer
                          </TableCell>
                          <TableCell
                            sx={{
                              borderBottom: "none",
                              fontWeight: "bold",
                              color: "white",
                            }}
                            align="center"
                          >
                            ROI times Active Customer
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {salesMarketingMarketCustTable.map((row: any) => (
                          <TableRow
                            key={row["Programs"]}
                            sx={{
                              borderBottom: "none",
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{
                                borderBottom: "none",
                                fontWeight: "bold",
                              }}
                            >
                              {row["Programs"]}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "none",
                              }}
                              align="center"
                            >
                              {row["Transaction per Customer"]}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "none",
                              }}
                              align="center"
                            >
                              {row["Revenue per customer"]}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "none",
                              }}
                              align="center"
                            >
                              {row["Change From Not Program"]}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "none",
                              }}
                              align="center"
                            >
                              {row["Cost per Customer"]}
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "none",
                              }}
                              align="center"
                            >
                              {row["ROI times Active Customers"]}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container item xs={12} sx={{ padding: "10px" }}>
          <Grid container item xs={6} sx={{ padding: "10px" }}>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Grid
                sx={{
                  backgroundColor: "#38B54A",
                  display: "flex",
                  padding: "5px 0px 5px 20px",
                  alignItems: "center",
                  borderRadius: "5px 5px 0px 0px",
                  border: "1px solid grey",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ color: "#FFFFFF", fontWeight: "bold" }}
                >
                  Top 20 Products by{" "}
                  {activeCustomerCount === "CUSTOMER_COUNT"
                    ? "Customer Count"
                    : "Revenue"}
                </Typography>
              </Grid>
              {productByCustomerCountLoading ? (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "300px",
                    border: "1px solid grey",
                    width: "100%",
                  }}
                >
                  <MiniSpinner />
                </Grid>
              ) : (
                <Grid
                  container
                  item
                  xs={12}
                  sx={{
                    padding: "10px 20px 10px 20px",
                    border: "1px solid grey",
                    backgroundColor: "#FFFFFF",
                    gap: 2,
                  }}
                >
                  <Grid sx={{ width: "100%" }}>
                    <Grid
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Grid sx={{ display: "flex" }}>
                        <Grid
                          sx={{
                            backgroundColor: "rgba(231, 231, 231, 1)",
                            display: "flex",
                            borderRadius: "10px",
                            padding: "2px",
                          }}
                        >
                          <Grid
                            sx={{ justifyContent: "center", padding: "5px" }}
                          >
                            <Button
                              onClick={() =>
                                handleCustomerCountNav("CUSTOMER_COUNT")
                              }
                              variant="outlined"
                              sx={{
                                color: "black",
                                padding: "0px 20px 0px 20px",
                                backgroundColor:
                                  activeCustomerCount === "CUSTOMER_COUNT"
                                    ? "#FFFFFF"
                                    : "rgba(231, 231, 231, 1)",
                              }}
                            >
                              CUSTOMER COUNT
                            </Button>
                          </Grid>
                          <Grid
                            sx={{ justifyContent: "center", padding: "5px" }}
                          >
                            <Button
                              onClick={() => handleCustomerCountNav("REVENUE")}
                              variant="outlined"
                              sx={{
                                color: "black",
                                padding: "0px 20px 0px 20px",
                                backgroundColor:
                                  activeCustomerCount === "REVENUE"
                                    ? "#FFFFFF"
                                    : "rgba(231, 231, 231, 1)",
                              }}
                            >
                              REVENUE
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} sx={{ height: "300px" }}>
                    {activeCustomerCount === "CUSTOMER_COUNT" ? (
                      <BarChart
                        series={[
                          {
                            data: salesMarketingProductCustomerChartdata,
                            label: "Average",
                            color: "rgba(49, 144, 242, 1)",
                          },
                        ]}
                        yAxis={[
                          {
                            scaleType: "band",
                            data: salesMarketingProductCustomerChartLabel,
                          },
                        ]}
                        slotProps={{
                          legend: {
                            direction: "row",
                            position: { vertical: "top", horizontal: "right" },
                            padding: 0,
                            itemMarkWidth: 15,
                            itemMarkHeight: 15,
                          },
                        }}
                        margin={{
                          left: 100,
                          right: 20,
                          top: 40,
                          bottom: 20,
                        }}
                        layout="horizontal"
                        grid={{ vertical: true, horizontal: true }}
                      />
                    ) : (
                      <BarChart
                        series={[
                          {
                            data: salesMarketingProductRevenueChartData,
                            label: "Average",
                            color: "rgba(49, 144, 242, 1)",
                          },
                        ]}
                        yAxis={[
                          {
                            scaleType: "band",
                            data: salesMarketingProductRevenueChartLabel,
                          },
                        ]}
                        slotProps={{
                          legend: {
                            direction: "row",
                            position: { vertical: "top", horizontal: "right" },
                            padding: 0,
                            itemMarkWidth: 15,
                            itemMarkHeight: 15,
                          },
                        }}
                        margin={{
                          left: 120,
                          right: 20,
                          top: 40,
                          bottom: 20,
                        }}
                        layout="horizontal"
                        grid={{ vertical: true, horizontal: true }}
                      />
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container item xs={6} sx={{ padding: "10px" }}>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Grid
                sx={{
                  backgroundColor: "#38B54A",
                  display: "flex",
                  padding: "5px 0px 5px 20px",
                  alignItems: "center",
                  borderRadius: "5px 5px 0px 0px",
                  border: "1px solid grey",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ color: "#FFFFFF", fontWeight: "bold" }}
                >
                  {activeSMRevenueMonth === "STABLE_ACCOUNT"
                    ? "Revenue"
                    : "Gross Margin"}{" "}
                  Month
                </Typography>
              </Grid>
              {rankByStableAccountLoading ? (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "300px",
                    border: "1px solid grey",
                    width: "100%",
                  }}
                >
                  <MiniSpinner />
                </Grid>
              ) : (
                <Grid
                  container
                  item
                  xs={12}
                  sx={{
                    padding: "10px 20px 10px 20px",
                    border: "1px solid grey",
                    backgroundColor: "#FFFFFF",
                    gap: 2,
                  }}
                >
                  <Grid
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {activeSMRevenueMonth === "STABLE_ACCOUNT" ? (
                      <Grid
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          gap: 1,
                        }}
                      >
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                          STABLE ACCOUNT TARGET
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={{
                            color: "rgba(0, 189, 170, 1)",
                            fontWeight: "bold",
                          }}
                        >
                          &gt; 40%
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                          CUSTOMER EMAIL % TARGET
                        </Typography>
                        <Typography sx={{ color: "rgba(0, 189, 170, 1)" }}>
                          &gt;95%
                        </Typography>
                      </Grid>
                    )}
                    <Grid sx={{}}>
                      <Grid sx={{ display: "flex" }}>
                        <Grid
                          sx={{
                            backgroundColor: "rgba(231, 231, 231, 1)",
                            display: "flex",
                            borderRadius: "10px",
                            padding: "2px",
                          }}
                        >
                          <Grid
                            sx={{ justifyContent: "center", padding: "5px" }}
                          >
                            <Button
                              onClick={() =>
                                handleSMRevenueMonthNav("STABLE_ACCOUNT")
                              }
                              variant="outlined"
                              sx={{
                                color: "black",
                                padding: "0px 20px 0px 20px",
                                backgroundColor:
                                  activeSMRevenueMonth === "STABLE_ACCOUNT"
                                    ? "#FFFFFF"
                                    : "rgba(231, 231, 231, 1)",
                              }}
                            >
                              STABLE ACCOUNT
                            </Button>
                          </Grid>
                          <Grid
                            sx={{ justifyContent: "center", padding: "5px" }}
                          >
                            <Button
                              onClick={() => handleSMRevenueMonthNav("EMAIL")}
                              variant="outlined"
                              sx={{
                                color: "black",
                                padding: "0px 20px 0px 20px",
                                backgroundColor:
                                  activeSMRevenueMonth === "EMAIL"
                                    ? "#FFFFFF"
                                    : "rgba(231, 231, 231, 1)",
                              }}
                            >
                              EMAIL %
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12} sx={{ height: "300px" }}>
                    {activeSMRevenueMonth === "STABLE_ACCOUNT" ? (
                      <BarChart
                        series={[
                          {
                            data: salesMarketingStableAccountChartData,
                            label: "Sales Rep Stable Account %",
                            color: "rgba(49, 144, 242, 1)",
                          },
                        ]}
                        yAxis={[
                          {
                            scaleType: "band",
                            data: salesMarketingStableAccountChartLabel,
                          },
                        ]}
                        slotProps={{
                          legend: {
                            direction: "row",
                            position: { vertical: "top", horizontal: "right" },
                            padding: 0,
                            itemMarkWidth: 15,
                            itemMarkHeight: 15,
                          },
                        }}
                        margin={{
                          left: 150,
                          right: 20,
                          top: 40,
                          bottom: 20,
                        }}
                        layout="horizontal"
                        grid={{ vertical: true, horizontal: true }}
                      />
                    ) : (
                      <BarChart
                        series={[
                          {
                            data: salesMarketingEmailChartData,
                            label: "Sales Rep Email %",
                            color: "rgba(49, 144, 242, 1)",
                          },
                        ]}
                        yAxis={[
                          {
                            scaleType: "band",
                            data: salesMarketingEmailChartLabel,
                          },
                        ]}
                        slotProps={{
                          legend: {
                            direction: "row",
                            position: { vertical: "top", horizontal: "right" },
                            padding: 0,
                            itemMarkWidth: 15,
                            itemMarkHeight: 15,
                          },
                        }}
                        margin={{
                          left: 150,
                          right: 20,
                          top: 40,
                          bottom: 20,
                        }}
                        layout="horizontal"
                        grid={{ vertical: true, horizontal: true }}
                      />
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SalesMarketing;
